import React, { Component } from "react";
// import "./navbar.css";
import Nav from "react-bootstrap/Nav";
import NavbarBrand from "react-bootstrap/NavbarBrand";
import Navbar from "react-bootstrap/Navbar";
import { NavDropdown } from "react-bootstrap";
import NavItem from "react-bootstrap/NavItem";
import NavLink from "react-bootstrap/NavLink";
import matchLabLogoName from "../assets/static-logos/11102018_matchlab_logo_name_PSD.png";
import matchLabNameOnly from "../assets/static-logos/11102018_matchlab_name_PSD.png";
import matchLabLogoOnly from "../assets/static-logos/11102018_matchlab_logo_PSD.png";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Container } from "react-bootstrap";
import Button from "react-bootstrap/Button";
// import AnalyzePartners from '../screens/analyzePartners.js';
function ToggleButton(props) {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
      }}
    >
      <Button variant="outline-primary" style={{ height: 30 }} {...props} />
    </div>
  );
}

// export default function OnePagerNavBar(props) {
//   return (
//     <Navbar collapseOnSelect fixed="top" expand="sm" bg="dark" variant="dark">
//       <Container style={{ width: "100%", flexDirection: "row" }}>
//         <Navbar.Toggle aria-controls="responsive-navbar-nav" />
//         <Navbar.Collapse />
//         <Nav>
//           <Nav.Link href="/">Home</Nav.Link>
//         </Nav>
//       </Container>
//     </Navbar>
//   );
// }

export default class OnePagerNavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="nav-bar-wrapper">
        <Navbar className="nav-bar" collapseOnSelect fixed="top" fluid>
          <NavbarBrand>
            <NavLink className="logo-link nav-link" style={{ textDecoration: "none" }} to="/">
              <img className="nav-logo" src={matchLabLogoName} alt="matchlab-logo" />
            </NavLink>
          </NavbarBrand>
          {/* <Navbar.Toggle as={ToggleButton} /> */}
          {/* <Navbar.Collapse> */}
          {/* <Nav> */}
          {/* <NavItem eventKey={1} href="/#about">
                <a className="nav-link">About</a>
              </NavItem>
              <NavItem eventKey={2} href="/#technology">
                <a className="nav-link">Our Technology</a>
              </NavItem>
              <NavItem eventKey={3} href="/#team">
                <a className="nav-link">Team</a>
              </NavItem> */}
          {/* <NavDropdown eventKey={2} title="Products" id="nav-product-dropdown">
                            <MenuItem eventKey={2.1} href="/matchsmart">MatchSmart</MenuItem>
                            <MenuItem eventKey={2.2} href="/matchanalytics">MatchAnalytics</MenuItem>
                        </NavDropdown> */}
          {/* <NavItem eventKey={4} href="/#partners">
                <a className="nav-link">Partners</a>
              </NavItem> */}
          {/* <NavItem>
                            <NavLink to="/researchers">
                                <a className="nav-link">For Researchers</a>
                            </NavLink>
                        </NavItem> */}
          {/* <NavItem eventKey={4} href="#">
                            <NavLink className="nav-link" style={{ textDecoration: 'none' }} to="/investors">Investors</NavLink>
                        </NavItem> */}
          {/* <NavItem eventKey={6} href="mailto:info@matchlab.ai">
                <a className="nav-link" href="mailto:info@matchlab.ai">
                  Contact
                </a>
              </NavItem> */}
          {/* </Nav> */}
          {/* </Navbar.Collapse> */}
        </Navbar>
      </div>
    );
  }
}
