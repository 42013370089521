import React, { Component } from "react";
import "./footer.css";
import matchlabLogoTinyGif from "../assets/images/matchlab_logo_tiny.gif";
import Fade from "react-reveal/Fade";

export class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <footer>
        <Fade bottom distance={"50px"}>
          <div className="footer-content">
            <div className="footer-gif">
              <img
                className="matchlab-logo-tiny-gif"
                src={matchlabLogoTinyGif}
                alt="matchlab-logo-tiny"
              />
            </div>
            <div className="footer-description">
              <h3>
                Skinnovation <i>Accelerated</i>
              </h3>
            </div>
            <div className="footer-links">
              <ul className="footer-partner-links">
                <div className="footer-link-header">Company</div>
                {/* <li><a href="#">Sign Up</a></li>
                        <li><a href="#">Download App</a></li> */}
                <li>
                  <a href="/#about">About</a>
                </li>
                <li>
                  <a href="/#products">Products</a>
                </li>
                <li>
                  <a href="/#partners">Partners</a>
                </li>
                <li>
                  <a href="/#find-out-more">Stay Connected</a>
                </li>
              </ul>
              <ul className="footer-derm-links">
                <div className="footer-link-header">Investors</div>
                <li>
                  <a href="/investors">Get Involved</a>
                </li>
                <li>
                  <a href="/investors#team">Meet the Team</a>
                </li>
                {/* <li><a href="#">Get in Touch</a></li> */}
              </ul>
              <ul className="footer-social">
                <div className="footer-link-header">Social</div>
                {/* <li><a href="#">Facebook</a></li> */}
                <li>
                  <a href="https://www.linkedin.com/company/matchlab-ai/">LinkedIn</a>
                </li>
              </ul>
            </div>
          </div>
        </Fade>
      </footer>
    );
  }
}

{
  /* // export default Footer; */
}
