import React, { Component } from "react";
import { OnePagerFooter } from "../components/onepagerfooter.js";
import "./homepage.css";
import {
  Jumbotron,
  Button,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Nav,
  Navbar,
  NavItem,
  NavDropdown,
  MenuItem,
  Tab,
  Row,
  Col,
} from "react-bootstrap";
import OnePagerNavBar from "../components/onepager_navbar.js";
import { Chart } from "react-google-charts";
import Fade from "react-reveal/Fade";

export class Researchers extends React.PureComponent {
  render() {
    return <section id="researchers" className="researchers"></section>;
  }
}

export class ResearchersPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <OnePagerNavBar />
        <Researchers />
        <OnePagerFooter />
      </div>
    );
  }
}
