import React, { Component } from 'react';
import { Footer } from '../components/footer.js';
import './investors.css';
import { Jumbotron, Button, Form, FormGroup, ControlLabel, FormControl, Nav, Navbar, NavItem, NavDropdown, MenuItem, Tab, Row, Col } from 'react-bootstrap';
import matchLabLogoName from '../assets/static-logos/11102018_matchlab_logo_name_PSD.png';
import matchLabNameOnly from '../assets/static-logos/11102018_matchlab_name_PSD.png';
import matchLabLogoOnly from '../assets/static-logos/11102018_matchlab_logo_PSD.png';
import { NavBar } from '../components/navbar.js';
import Fade from 'react-reveal/Fade';


const LANGUAGE = "English";

export class MatchAnalytics extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }
  render() {
    return ( 
        <div>
            <NavBar />

            <Footer />
        </div>
     );
  }
}