import React, { Component } from "react";
import { Footer } from "../components/footer.js";
import "./homepage.css";
import {
	Jumbotron,
	Button,
	Form,
	FormGroup,
	ControlLabel,
	FormControl,
	Nav,
	Navbar,
	NavItem,
	NavDropdown,
	MenuItem,
	Tab,
	Row,
	Col,
} from "react-bootstrap";
import matchLabLogoName from "../assets/static-logos/11102018_matchlab_logo_name_PSD.png";
import matchLabNameOnly from "../assets/static-logos/11102018_matchlab_name_PSD.png";
import matchLabLogoOnly from "../assets/static-logos/11102018_matchlab_logo_PSD.png";
import { NavBar } from "../components/navbar.js";
import interfaceImage from "../assets/images/interface.png";
import detectionBefore from "../assets/images/detection_before.jpg";
import detectionAfter from "../assets/images/detection_after2.jpg";
import standardizationBefore from "../assets/images/standardization_before.jpg";
import standardizationAfter from "../assets/images/standardization_after.jpg";
import ideasIcon from "../assets/icons/ideas-icon.png";
import nameCardIcon from "../assets/icons/name-card-icon.png";
import settingsIcon from "../assets/icons/settings-icon.png";
import flowMarketIcon from "../assets/icons/flow-market-icon.png";
import personIcon from "../assets/icons/person-icon.png";
import Text from "../assets/text/homepageText.json";
import blankIphone from "../assets/images/blank_iphone.png";
import dummyArmImage from "../assets/images/dummy_arm_rash.jpg";
import dummyArmImageMarkedUp from "../assets/images/dummy_arm_rash_markedup.jpg";
import focusDemoHandLg from "../assets/images/focus_demo_hand_lg.jpg";
import focusDemoBgLg from "../assets/images/focus_demo_bg_lg.jpg";
import focusDemoHandMd from "../assets/images/focus_demo_hand_md.jpg";
import focusDemoBgMd from "../assets/images/focus_demo_bg_md.jpg";
import focusDemoHandSm from "../assets/images/focus_demo_hand_sm.jpg";
import focusDemoBgSm from "../assets/images/focus_demo_bg_sm.jpg";
import scabTimeSeries from "../assets/images/scab_timeseries.gif";
import aid from "../assets/images/aid2.png";
import leoPharma from "../assets/images/leopharma2.png";
import mitHackingMedicine from "../assets/images/mithackingmedicine.png";
import Fade from "react-reveal/Fade";
import { Carousel } from "react-bootstrap";
import segmentedPoseTracking from "../assets/gifs/segmentedPoseTracking.gif";

// import creamOnSkinImage from '../assets/images/cream_on_skin.jpg';
// import amberBottleImage from '../assets/images/amber_bottle_liquid.jpg';

const LANGUAGE = "English";

const styles = {
	// use 'styles={styles.matchLabNameOnly}' as attribute
	// ...: {
	// ...
	// },
	// ...: {
	// ...
	// }
};

// class DemoSection extends React.PureComponent{
//     render(){
//         return(
//             <section className="demo-section" id="demo-section">
//             <div className="demo-section-header section-header">
//                 <h2>Don't just take our word for it. See it for yourself.</h2>
//                 {/* <h3>See it in action</h3> */}
//             </div>
//             <div className="demo-container">
//             <Tab.Container id="left-tabs-example" defaultActiveKey="first">
//                 <Row className="clearfix">
//                     {/* <Col sm={4}> */}
//                         <Nav bsStyle="pills" className="demo-tab-container" stacked>
//                             <NavItem eventKey="first" className="demo-tab">Real-time Skin Analytics</NavItem>
//                             <NavItem eventKey="second" className="demo-tab">Full-body Skin Segmentation</NavItem>
//                         </Nav>
//                     {/* </Col> */}
//                     {/* <Col sm={8}> */}
//                     <Col>
//                         <Tab.Content animation>
//                             <Tab.Pane eventKey="first" className="demo-content-container">
//                                 <p>High-throughput skin segmentation and feature annotation</p>
//                                 <div className="demo-before demo-container">
//                                     <img src={ detectionBefore } alt="demo-image" />
//                                 </div>
//                                 <div className="demo-after demo-container">
//                                     <img src={ detectionAfter } alt="demo-image" />
//                                 </div>
//                             </Tab.Pane>

//                             <Tab.Pane eventKey="second" className="demo-content-container">
//                                 <p>Real-time adaptive skin detection for all skin tones</p>
//                                 <div className="demo-before demo-container">
//                                     <img src={ standardizationBefore } alt="demo-image" />                                </div>
//                                 <div className="demo-after demo-container">
//                                     <img src={ standardizationAfter } alt="demo-image" />
//                                 </div>
//                             </Tab.Pane>
//                         </Tab.Content>
//                     </Col>
//                 </Row>
//             </Tab.Container>
//             </div>
//         </section>
//         )
//     }

// }

// class HowItWorks extends React.PureComponent{
//     render(){
//         return(
//             <section className="steps-section">
//             <div className="section-header">
//                 <h2>{Text['stepsHeader'][LANGUAGE]}</h2>
//             </div>
//             <ol className="steps">
//                 <li className="step step-1">
//                     <div className="step-description-container">
//                         <h3>{Text['step1Header'][LANGUAGE]}</h3>
//                         <div className="step-description">
//                             <p>{Text['step1Description'][LANGUAGE]}</p>
//                         </div>
//                     </div>
//                     <div className="step-image-container">
//                         <img src={personIcon} alt="step-image" />
//                     </div>
//                 </li>
//                 <li className="step step-2">
//                     <div className="step-description-container">
//                         <h3>{Text['step2Header'][LANGUAGE]}</h3>
//                         <div className="step-description">
//                             <p>{Text['step2Description'][LANGUAGE]}</p>
//                         </div>
//                     </div>
//                     <div className="step-image-container">
//                         <img src={flowMarketIcon} alt="step-image" />
//                     </div>
//                 </li>
//                 <li className="step step-3">
//                     <div className="step-description-container">
//                         <h3>{Text['step3Header'][LANGUAGE]}</h3>
//                         <div className="step-description">
//                             <p>{Text['step3Description'][LANGUAGE]}</p>
//                         </div>
//                     </div>
//                     <div className="step-image-container">
//                         <img src={nameCardIcon} alt="step-image" />
//                     </div>
//                 </li>
//             </ol>
//         </section>
//         )
//     }
// }

class InfobarSection extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {};
		this.example = this.handleCountdown = this.handleCountdown.bind(this);
	}

	handleCountdown() {
		let today = new Date();
		let launchDate = new Date("2019/07/27");
		let countdownDays = Math.floor(
			(Date.UTC(launchDate.getFullYear(), launchDate.getMonth(), launchDate.getDate()) -
				Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())) /
				(1000 * 60 * 60 * 24)
		);
		return countdownDays;
	}

	render() {
		return (
			<section className="hp-infobar">
				<Fade bottom delay={1000} distance={"50px"}>
					<div>
						<h1>{this.handleCountdown()} days to launch</h1>
						<p>
							Come join us at the AID Summer Conference on July 26th as we will be unveiling our
							first product, <strong>SITEMATCH</strong>
						</p>
					</div>
				</Fade>
			</section>
		);
	}
}

class HeroSection extends React.PureComponent {
	render() {
		return (
			<section className="hp-hero-section">
				<header className="hp-hero-container">
					{/* <h1 className="heroH1">MatchLab.ai</h1> */}
					{/* <div className="heroImgContainer">
                        <img className="heroLogo" src={matchLabNameOnly} alt="matchlab" />
                    </div> */}
					<Fade bottom delay={1000} distance={"50px"}>
						<div className="hp-hero-section-header">
							<h1 className="hero-header">
								{Text["heroHeader"][LANGUAGE]}
								{/* {Text['heroHeader'][LANGUAGE].split(" ").slice(0,4).join(" ") + " "} */}
								{/* <br />
                            {Text['heroHeader'][LANGUAGE].split(" ").slice(4,7).join(" ") + " "}
                            {Text['heroHeader'][LANGUAGE].split(" ").slice(7).join(" ")} */}
							</h1>

							<form
								id="hero-signup"
								className="signup-form validate"
								action="https://nickrance.us16.list-manage.com/subscribe/post?u=4da03f423ea15a7261de121d2&amp;id=44e5aa8dfb"
								method="post"
								id="mc-embedded-subscribe-form"
								name="mc-embedded-subscribe-form"
								target="_blank"
								novalidate=""
							>
								<input type="email" id="mce-EMAIL" name="EMAIL" placeholder="Email address" />
								<button type="submit">Learn More</button>
							</form>
						</div>
					</Fade>
				</header>
			</section>
		);
	}
}

class NewsSection extends React.Component {
	style = {};
	render() {
		return (
			<section className="hp-news-section">
				{/* <Fade bottom distance={'50px'}> */}
				<article>
					{/* <h2 className="section-header">News</h2> */}

					<Carousel controls={false} fade={true}>
						<Carousel.Item>
							<div className="carousel-item">
								{/* <img
                                className="d-block w-100"
                                src="holder.js/800x400?text=First slide&bg=373940"
                                alt="First slide"
                                /> */}
								<p>{Text["news"][LANGUAGE]["3"]}</p>
							</div>
							{/* <Carousel.Caption>
                                <h3>Second slide label</h3>
                                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                                </Carousel.Caption> */}
						</Carousel.Item>

						<Carousel.Item>
							<div className="carousel-item">
								<p>{Text["news"][LANGUAGE]["2"]}</p>
							</div>
						</Carousel.Item>

						<Carousel.Item>
							<div className="carousel-item">
								<p>{Text["news"][LANGUAGE]["1"]}</p>
							</div>
						</Carousel.Item>
					</Carousel>
				</article>
				{/* </Fade> */}
			</section>
		);
	}
}

class LearnMoreSection extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};

		// this.handleFocusOnSubject = this.handleFocusOnSubject.bind(this);
	}

	// handleFocusOnSubject(size) {
	//     document.getElementById(`background-focused-${size}`).style.opacity = "0";
	//     document.getElementById(`background-focused-${size}`).style.zIndex = "-1";
	//     document.getElementById(`hand-focused-${size}`).style.opacity = "1";
	//     document.getElementById(`hand-focused-${size}`).style.zIndex = "1";
	// }

	// handleFocusOnBg(size) {
	//     document.getElementById(`background-focused-${size}`).style.opacity = "1";
	//     document.getElementById(`background-focused-${size}`).style.zIndex = "1";
	//     document.getElementById(`hand-focused-${size}`).style.opacity = "0";
	//     document.getElementById(`hand-focused-${size}`).style.zIndex = "-1";
	// }

	render() {
		return (
			<section id="about" className="hp-learn-more-section">
				<Fade bottom distance={"50px"}>
					<div className="app-image-container">
						<div className="smartphone">
							<img src={segmentedPoseTracking} alt="" />
						</div>

						{/* <div className="smartphone"> */}
						{/* Large */}
						{/* <img alt="background-focused-lg" id="background-focused-lg" src={focusDemoBgLg} usemap="#background-focused-lg" />
                        <map name="background-focused-lg">
                            <area alt="background-focused-lg" shape="poly" coords="0,380,44,352,99,302,150,275,194,238,210,236,215,255,177,299,139,336,184,333,256,314,323,300,323,334,232,369,234,382,302,372,323,376,323,576,0,578" onClick={(e) => this.handleFocusOnSubject("lg", e)} />
                        </map>

                        <img alt="hand-focused-lg" id="hand-focused-lg" src={focusDemoHandLg} usemap="#hand-focused-lg"/>
                        <map name="hand-focused-lg">
                            <area alt="hand-focused-lg" shape="poly" coords="1,0,0,376,92,303,179,255,199,228,218,241,194,285,132,332,176,335,263,309,323,301,323,334,230,373,251,378,323,369,322,1" onClick={(e) => this.handleFocusOnBg("lg", e)} />
                        </map> */}

						{/* Medium */}
						{/* <img alt="background-focused-md" id="background-focused-md" src={focusDemoBgMd} usemap="#background-focused-md" />
                        <map name="background-focused-md">
                            <area alt="background-focused-md" shape="poly" coords="1,322,38,298,82,257,122,237,148,220,167,202,181,208,168,236,143,258,119,280,128,287,167,281,218,267,273,254,273,287,224,303,197,313,203,324,251,317,273,317,273,488,1,488" onClick={(e) => this.handleFocusOnSubject("md", e)} />
                        </map>

                        <img alt="hand-focused-md" id="hand-focused-md" src={focusDemoHandMd} usemap="#hand-focused-md"/>
                        <map name="hand-focused-md">
                            <area alt="hand-focused-md" shape="poly" coords="1,322,38,298,82,257,122,237,148,220,167,202,181,208,168,236,143,258,119,280,128,287,167,281,218,267,273,254,273,287,224,303,197,313,203,324,251,317,273,317,273,1,1,1" onClick={(e) => this.handleFocusOnBg("md", e)} />
                        </map> */}

						{/* Small */}
						{/* <img alt="background-focused-sm" id="background-focused-sm" src={focusDemoBgSm} usemap="#background-focused-sm" />
                        <map name="background-focused-sm">
                            <area alt="background-focused-sm" shape="poly" coords="0,235,26,219,59,186,95,168,111,159,120,148,131,154,126,170,100,193,88,204,111,205,156,194,198,188,199,207,144,227,149,233,198,231,199,353,1,354" onClick={(e) => this.handleFocusOnSubject("sm", e)} />
                        </map>

                        <img alt="hand-focused-sm" id="hand-focused-sm" src={focusDemoHandSm} usemap="#hand-focused-sm"/>
                        <map name="hand-focused-sm">
                            <area alt="hand-focused-sm" shape="poly" coords="0,235,26,219,59,186,95,168,111,159,120,148,131,154,126,170,100,193,88,204,111,205,156,194,198,188,199,207,144,227,149,233,198,231,199,1,1,1" onClick={(e) => this.handleFocusOnBg("sm", e)} />
                        </map>
                    </div>
                    <p>(Try clicking on the wart!)</p> */}
					</div>

					<article className="app-content-container">
						<h2 className="section-header">{Text["learnMoreAppHeader2"][LANGUAGE]}</h2>
						<p>{Text["learnMoreAppContent2"][LANGUAGE]}</p>
					</article>
				</Fade>
			</section>
		);
	}
}

class TechnologySection extends React.PureComponent {
	render() {
		return (
			<section id="products" className="hp-technology-section">
				<article className="hp-technology-content-container">
					<Fade bottom distance={"50px"}>
						<h2 className="section-header">{Text["demoHeader2"][LANGUAGE]}</h2>
					</Fade>
					{/* <h2 className="section-header">{Text['demo'+this.props.feature+'Header'][LANGUAGE]}</h2>
                    <p className="hero-subheader">{Text['demo'+this.props.feature+'Subheader'][LANGUAGE]}</p> */}
					{/* <p className="demo-content">{Text['demoContent'][LANGUAGE]}</p> */}

					{/* <p>High-throughput skin segmentation and feature annotation</p> */}
					<Fade bottom distance={"50px"}>
						<div className="demo-container-face demo-container">
							<div className="demo-container-images demo-container-images-odd gradient-left-background">
								<img class="img-before" src={detectionBefore} alt="face-before-detection" />
								<img class="img-after" src={detectionAfter} alt="face-after-detection" />
							</div>
							<div className="demo-container-text demo-container-text-odd">
								{/* <h3>Real-Time Face Tracking</h3> */}
								{/* <h3>MatchSmart for Surgeons</h3> */}
								{/* <p>For dermatological images of the face, the lighting, focus, and even face orientation are tracked to ensure high quality reproducible image acquisition.</p> */}
								{/* <p>Coming July 26th at the AID Summer Conference, MatchSmart makes dermatological surgery smarter and safer with AI. Request a demo today.</p> */}
							</div>
						</div>
					</Fade>
					<Fade bottom distance={"50px"}>
						<div className="demo-container-back demo-container">
							<div className="demo-container-images demo-container-images-even gradient-right-background">
								<img
									class="img-before"
									src={standardizationBefore}
									alt="back-before-standardized"
								/>
								<img class="img-after" src={standardizationAfter} alt="back-after-standardized" />
							</div>
							<div className="demo-container-text demo-container-text-even">
								{/* <h3>General Body Skin Detection</h3> */}
								<h3>MatchAnalytics for Researchers</h3>
								{/* <p>For more general body parts, our smart segmentation algorithms isolate the skin from the background, allowing for an optimally focused picture to be taken of the afflicted region.</p> */}
								<p>
									We can collect standardized images, refocus within that image plain, and segment
									out skin for analytics on large datasets. Request for demo.
								</p>
							</div>
						</div>
					</Fade>
					{/* <Fade bottom distance={'50px'}>
                    <div className="demo-container-alignment demo-container">
                        <div className="demo-container-images demo-container-images-odd">  
                            <img class="gif-time-series" src={ scabTimeSeries } alt="time-series-alignment" />
                        </div>
                        <div className="demo-container-text demo-container-text-odd">
                            <p>Accelerate analysis of patient image data with our automatic alignment algorithm, which works even in the presence of complex backgrounds.</p>
                        </div>
                    </div>
                    </Fade> */}
				</article>

				{/* <div class="hp-technology-image-container">
                    <img src={ dummyArmImageMarkedUp } alt="demo-skin-photo" />
                </div> */}
			</section>
		);
	}
}

class HowItWorks extends React.PureComponent {
	render() {
		return (
			<section className="steps-section">
				<Fade bottom distance={"50px"}>
					<h2 className="section-header">{Text["stepsHeader"][LANGUAGE]}</h2>
				</Fade>
				<ol className="steps">
					<Fade bottom distance={"50px"}>
						<li className="step step-1">
							<div className="step-description-container">
								<h3>{Text["step1Header"][LANGUAGE]}</h3>
								<div className="step-description">
									<p>{Text["step1Description"][LANGUAGE]}</p>
								</div>
							</div>
							<div className="step-image-container">
								<img src={personIcon} alt="step-image" />
							</div>
						</li>
					</Fade>
					<Fade bottom distance={"50px"}>
						<li className="step step-2">
							<div className="step-description-container">
								<h3>{Text["step2Header"][LANGUAGE]}</h3>
								<div className="step-description">
									<p>{Text["step2Description"][LANGUAGE]}</p>
								</div>
							</div>
							<div className="step-image-container">
								<img src={flowMarketIcon} alt="step-image" />
							</div>
						</li>
					</Fade>
					<Fade bottom distance={"50px"}>
						<li className="step step-3">
							<div className="step-description-container">
								<h3>{Text["step3Header"][LANGUAGE]}</h3>
								<div className="step-description">
									<p>{Text["step3Description"][LANGUAGE]}</p>
								</div>
							</div>
							<div className="step-image-container">
								<img src={nameCardIcon} alt="step-image" />
							</div>
						</li>
					</Fade>
				</ol>
			</section>
		);
	}
}

class FindOutMoreSection extends React.PureComponent {
	render() {
		return (
			<section id="find-out-more" className="find-out-more-section">
				<Fade bottom distance={"50px"}>
					<header className="find-out-more-container">
						{/* <h1 className="heroH1">MatchLab.ai</h1> */}
						{/* <div className="heroImgContainer">
                            <img className="heroLogo" src={matchLabNameOnly} alt="matchlab" />
                        </div> */}
						<div className="find-out-more-section-header">
							<h1 className="section-header">
								{Text["findOutMoreHeader"][LANGUAGE]}
								{/* {Text['heroText'][LANGUAGE].split(" ").slice(0,4).join(" ") + " "} 
                                <i>
                                    {Text['heroText'][LANGUAGE].split(" ").slice(4,7).join(" ") + " "}
                                </i>
                                {Text['heroText'][LANGUAGE].split(" ").slice(7).join(" ")} */}
							</h1>
							<p className="hero-sub-header">{Text["findOutMoreSubHeader"][LANGUAGE]}</p>
							<form
								className="signup-form validate"
								action="https://nickrance.us16.list-manage.com/subscribe/post?u=4da03f423ea15a7261de121d2&amp;id=44e5aa8dfb"
								method="post"
								id="mc-embedded-subscribe-form"
								name="mc-embedded-subscribe-form"
								target="_blank"
								novalidate=""
							>
								<input type="email" id="mce-EMAIL" name="EMAIL" placeholder="Email address" />
								<button type="submit">Submit</button>
							</form>
						</div>
					</header>
				</Fade>
			</section>
		);
	}
}

export class PartnersSection extends React.PureComponent {
	render() {
		return (
			<section id="partners" className="hp-partners-section">
				<Fade bottom distance={"50px"}>
					<h2 className="section-header">Our Partners</h2>
				</Fade>
				<div className="partners-container">
					<Fade bottom distance={"50px"}>
						<div className="partner-container">
							<img src={leoPharma} alt="leo-pharma" />
						</div>
					</Fade>
					<Fade bottom distance={"50px"} delay={200}>
						<div className="partner-container">
							<img src={mitHackingMedicine} alt="mit-hacking-medicine" />
						</div>
					</Fade>
					<Fade bottom distance={"50px"} delay={400}>
						<div className="partner-container partner-aid">
							<img id="aid" src={aid} alt="advancing-innovation-in-dermatology" />
						</div>
					</Fade>
				</div>
			</section>
		);
	}
}

export class Homepage extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}
	render() {
		return (
			<div>
				<NavBar />
				<InfobarSection />
				<HeroSection />
				<NewsSection />
				{/* <ControlledCarousel/> */}
				<LearnMoreSection />
				<TechnologySection feature={1} />
				{/* <DemoSection feature={2}/>
            <DemoSection feature={3}/> */}
				{/* <HowItWorks/> */}
				{/* <PartnersSection /> */}
				<FindOutMoreSection />
				<Footer />
			</div>
		);
	}
}

{
	/* // export default AnalyzePartner; */
}
