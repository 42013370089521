import React, { Component } from "react";
// import logo from './logo.svg';
import { createBrowserRouter, Route } from "react-router-dom";
import "./App.css";
import "./bootstrap/css/bootstrap.min.css"; // v3.3.7
import { AnalyzePartners } from "./screens/analyze_partners.js";
import { AnalyzeDermatologists } from "./screens/analyze_dermatologists.js";
import { Homepage } from "./screens/homepage.js";
import { Investors } from "./screens/investors.js";
import { MatchSmart } from "./screens/matchsmart.js";
import { MatchAnalytics } from "./screens/matchanalytics.js";
import OnePager from "./screens/onepager.js";
import { Researchers, ResearchersPage } from "./screens/researchers.js";

// class App extends Component {
//   render() {
//     console.log("App Rendering");
//     return (
//       <div className="App">
//         {/* Had to use 'exact' attribute. "The concept of an IndexRoute is gone,
//         and instead you can use exact to match indexes. This syntax also provides a
//         lot more flexibility over IndexRoute; you can now specify that certain
//         routes must match with or without a trailing slash."
//         https://medium.com/@ivanmontiel/changing-old-habits-in-react-router-4-8483ee5e087c */}

//         <Route exact path="/" component={OnePager} />

//         {/* Add this back in later */}
//         {/* <Route exact path="/" component={ Homepage }/> */}

//         {/* <Route path="/partners" component={ AnalyzePartners }/>
//         <Route path="/dermatologists" component={ AnalyzeDermatologists }/> */}
//         <Route path="/investors" component={Investors} />
//         <Route path="/researchers" component={ResearchersPage} />
//         <Route path="/matchsmart" component={MatchSmart} />
//         <Route path="/matchanalytics" component={MatchAnalytics} />
//       </div>
//     );
//   }
// }

const AppRouter = createBrowserRouter([
  {
    path: "/",
    element: <OnePager />,
  },
  {
    path: "/investors",
    element: <Investors />,
  },
  {
    path: "/researchers",
    element: <Researchers />,
  },
  {
    path: "/matchsmart",
    element: <MatchSmart />,
  },
  {
    path: "/matchanalytics",
    element: <MatchAnalytics />,
  },
]);

export default AppRouter;
