import React, { Component } from "react";
import { Footer } from "../components/footer.js";
import "./investors.css";
import {
  Jumbotron,
  Button,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Nav,
  Navbar,
  NavItem,
  NavDropdown,
  MenuItem,
  Tab,
  Row,
  Col,
} from "react-bootstrap";
import matchLabLogoName from "../assets/static-logos/11102018_matchlab_logo_name_PSD.png";
import matchLabNameOnly from "../assets/static-logos/11102018_matchlab_name_PSD.png";
import matchLabLogoOnly from "../assets/static-logos/11102018_matchlab_logo_PSD.png";
import { NavBar } from "../components/navbar.js";
import interfaceImage from "../assets/images/interface.png";
import detectionBefore from "../assets/images/detection_before.jpg";
import detectionAfter from "../assets/images/detection_after2.jpg";
import standardizationBefore from "../assets/images/standardization_before.jpg";
import standardizationAfter from "../assets/images/standardization_after.jpg";
import ideasIcon from "../assets/icons/ideas-icon.png";
import nameCardIcon from "../assets/icons/name-card-icon.png";
import settingsIcon from "../assets/icons/settings-icon.png";
import flowMarketIcon from "../assets/icons/flow-market-icon.png";
import personIcon from "../assets/icons/person-icon.png";
import Text from "../assets/text/investorsText.json";
import blankIphone from "../assets/images/blank_iphone.png";
import dummyArmImage from "../assets/images/dummy_arm_rash.jpg";
import dummyArmImageMarkedUp from "../assets/images/dummy_arm_rash_markedup.jpg";
import focusDemoHandLg from "../assets/images/focus_demo_hand_lg.jpg";
import focusDemoBgLg from "../assets/images/focus_demo_bg_lg.jpg";
import focusDemoHandMd from "../assets/images/focus_demo_hand_md.jpg";
import focusDemoBgMd from "../assets/images/focus_demo_bg_md.jpg";
import focusDemoHandSm from "../assets/images/focus_demo_hand_sm.jpg";
import focusDemoBgSm from "../assets/images/focus_demo_bg_sm.jpg";
import scabTimeSeries from "../assets/images/scab_timeseries.gif";
import aid from "../assets/images/aid2.png";
import leoPharma from "../assets/images/leopharma2.png";
import mitHackingMedicine from "../assets/images/mithackingmedicine.png";
import Fade from "react-reveal/Fade";
import { Carousel } from "react-bootstrap";
import teamNikkiImage from "../assets/images/team_nikki.png";
import teamAlexImage from "../assets/images/team_alex.png";
import teamNickImage from "../assets/images/team_nick.png";
import teamDivyaImage from "../assets/images/team_divya.png";
import teamChristianImage from "../assets/images/team_christian.png";

import advisorAnilAchyutaImage from "../assets/images/advisor_anilachyuta.png";
import advisorAlexCostaImage from "../assets/images/advisor_alexcosta.png";
import advisorAbrarQureshiImage from "../assets/images/advisor_abrarqureshi.png";

// import creamOnSkinImage from '../assets/images/cream_on_skin.jpg';
// import amberBottleImage from '../assets/images/amber_bottle_liquid.jpg';

const LANGUAGE = "English";

class InvestorsHeroSection extends React.PureComponent {
  render() {
    return (
      <section className="investors-hero-section">
        <header className="investors-hero-container">
          <Fade bottom delay={1000} distance={"50px"}>
            <div className="investors-hero-section-header">
              <h1 className="hero-header">
                {Text["investorsHeroHeader"][LANGUAGE]}
                <br />
                {/* {Text['investorsHeroHeader'][LANGUAGE].split(" ").slice(4,7).join(" ") + " "} */}
              </h1>
              <p className="hero-sub-header">{Text["investorsSubHeader"][LANGUAGE]}</p>
              <form
                id="investors-hero-signup"
                className="signup-form validate"
                action=""
                method="post"
                id=""
                name=""
                target="_blank"
                novalidate=""
              >
                <input type="email" id="mce-EMAIL" name="EMAIL" placeholder="Email address" />
                <button type="submit">Get involved</button>
              </form>
            </div>
          </Fade>
        </header>
      </section>
    );
  }
}

export class TeamSection extends React.PureComponent {
  render() {
    return (
      <section className="team-section" id="team">
        {/* <Fade bottom delay={1000} distance={'50px'}>
                    <h2 className="section-header">Meet Our Team</h2>
                </Fade> */}

        <Fade bottom distance={"50px"}>
          <h2 className="section-header">Leadership</h2>
          <div id="team-leadership-container" className="team-containers">
            {/* <div class="team-member-container" id="team-nick">
              <img src={teamNickImage} alt="" />
              <div class="team-member-description-container">
                <span class="team-member-name">
                  {Text["teamLeaders"][LANGUAGE]["Nick"]["name"]}
                </span>
                <span class="team-member-title">
                  {Text["teamLeaders"][LANGUAGE]["Nick"]["title"]}
                </span>
              </div>
            </div>
            <div class="team-member-container" id="team-alex">
              <img src={teamAlexImage} alt="" />
              <div class="team-member-description-container">
                <span class="team-member-name">
                  {Text["teamLeaders"][LANGUAGE]["Alex"]["name"]}
                </span>
                <span class="team-member-title">
                  {Text["teamLeaders"][LANGUAGE]["Alex"]["title"]}
                </span>
              </div>
            </div>
            <div class="team-member-container" id="team-divya">
              <img src={teamDivyaImage} alt="" />
              <div class="team-member-description-container">
                <span class="team-member-name">
                  {Text["teamLeaders"][LANGUAGE]["Divya"]["name"]}
                </span>
                <span class="team-member-title">
                  {Text["teamLeaders"][LANGUAGE]["Divya"]["title"]}
                </span>
              </div>
            </div> */}
            {/* <div class="team-member-container" id="team-christian">
                            <img src={teamChristianImage} alt=""/>
                            <div class="team-member-description-container">
                                <span class="team-member-name">{Text['teamLeaders'][LANGUAGE]['Christian']['name']}</span>
                                <span class="team-member-title">{Text['teamLeaders'][LANGUAGE]['Christian']['title']}</span>
                            </div>
                        </div> */}
            {/* <div class="team-member-container" id="team-nikki">
                            <img src={teamNikkiImage} alt=""/>
                            <div class="team-member-description-container">
                                <span class="team-member-name">{Text['teamLeaders'][LANGUAGE]['Nikki']['name']}</span>
                                <span class="team-member-title">{Text['teamLeaders'][LANGUAGE]['Nikki']['title']}</span>
                            </div>
                        </div> */}
          </div>
          {/* <div id="team-description-container">
                        <div class="team-member-description-container">
                            <h4>{Text['teamSection'][LANGUAGE]['Nikki']['name']}</h4>
                            <p>{Text['teamSection'][LANGUAGE]['Nikki']['title']}</p>
                        </div>
                        <div class="team-member-description-container">
                            <h4>{Text['teamSection'][LANGUAGE]['Alex']['name']}</h4>
                            <p>{Text['teamSection'][LANGUAGE]['Alex']['title']}</p>
                        </div>
                        <div class="team-member-description-container">
                            <h4>{Text['teamSection'][LANGUAGE]['Nick']['name']}</h4>
                            <p>{Text['teamSection'][LANGUAGE]['Nick']['title']}</p>
                        </div>
                        <div class="team-member-description-container">
                            <h4>{Text['teamSection'][LANGUAGE]['Divya']['name']}</h4>
                            <p>{Text['teamSection'][LANGUAGE]['Divya']['title']}</p>
                        </div>
                        <div class="team-member-description-container">
                            <h4>{Text['teamSection'][LANGUAGE]['Christian']['name']}</h4>
                            <p>{Text['teamSection'][LANGUAGE]['Christian']['title']}</p>
                        </div>
                    </div> */}
        </Fade>

        <Fade bottom distance={"50px"}>
          <h2 className="section-header">Advisors</h2>
          <div id="team-advisors-container" className="team-containers">
            <div class="team-member-container" id="advisor-anil">
              <img src={advisorAnilAchyutaImage} alt="" />
              <div class="team-member-description-container advisor-description-container">
                <span class="team-member-name">
                  {Text["teamAdvisors"][LANGUAGE]["Anil"]["name"]}
                </span>
                <span class="team-member-title">
                  {Text["teamAdvisors"][LANGUAGE]["Anil"]["title"]}
                </span>
                <span class="team-member-title">
                  {Text["teamAdvisors"][LANGUAGE]["Anil"]["organization"]}
                </span>
              </div>
            </div>
            <div class="team-member-container" id="advisor-alex">
              <img src={advisorAlexCostaImage} alt="" />
              <div class="team-member-description-container advisor-description-container">
                <span class="team-member-name">
                  {Text["teamAdvisors"][LANGUAGE]["Alex"]["name"]}
                </span>
                <span class="team-member-title">
                  {Text["teamAdvisors"][LANGUAGE]["Alex"]["title"]}
                </span>
                <span class="team-member-title">
                  {Text["teamAdvisors"][LANGUAGE]["Alex"]["organization"]}
                </span>
              </div>
            </div>
            <div class="team-member-container" id="advisor-abrar">
              <img src={advisorAbrarQureshiImage} alt="" />
              <div class="team-member-description-container advisor-description-container">
                <span class="team-member-name">
                  {Text["teamAdvisors"][LANGUAGE]["Abrar"]["name"]}
                </span>
                <span class="team-member-title">
                  {Text["teamAdvisors"][LANGUAGE]["Abrar"]["title"]}
                </span>
                <span class="team-member-title">
                  {Text["teamAdvisors"][LANGUAGE]["Abrar"]["organization"]}
                </span>
              </div>
            </div>
          </div>
          {/* <div id="team-description-container">
                        <div class="team-member-description-container">
                            <h4>{Text['teamSection'][LANGUAGE]['Nikki']['name']}</h4>
                            <p>{Text['teamSection'][LANGUAGE]['Nikki']['title']}</p>
                        </div>
                        <div class="team-member-description-container">
                            <h4>{Text['teamSection'][LANGUAGE]['Alex']['name']}</h4>
                            <p>{Text['teamSection'][LANGUAGE]['Alex']['title']}</p>
                        </div>
                        <div class="team-member-description-container">
                            <h4>{Text['teamSection'][LANGUAGE]['Nick']['name']}</h4>
                            <p>{Text['teamSection'][LANGUAGE]['Nick']['title']}</p>
                        </div>
                        <div class="team-member-description-container">
                            <h4>{Text['teamSection'][LANGUAGE]['Divya']['name']}</h4>
                            <p>{Text['teamSection'][LANGUAGE]['Divya']['title']}</p>
                        </div>
                        <div class="team-member-description-container">
                            <h4>{Text['teamSection'][LANGUAGE]['Christian']['name']}</h4>
                            <p>{Text['teamSection'][LANGUAGE]['Christian']['title']}</p>
                        </div>
                    </div> */}
        </Fade>
      </section>
    );
  }
}

export class AdvisorsSection extends React.PureComponent {
  render() {
    return (
      <section className="team-section" id="team">
        <Fade bottom delay={1000} distance={"50px"}>
          <h2 className="section-header">Advisors</h2>
          <div id="team-container">
            <div class="team-member-container" id="team-nick">
              <img src={teamNickImage} alt="" />
              <div class="team-member-description-container">
                <span class="team-member-name">
                  {Text["teamSection"][LANGUAGE]["Nick"]["name"]}
                </span>
                <span class="team-member-title">
                  {Text["teamSection"][LANGUAGE]["Nick"]["title"]}
                </span>
              </div>
            </div>
            <div class="team-member-container" id="team-alex">
              <img src={teamAlexImage} alt="" />
              <div class="team-member-description-container">
                <span class="team-member-name">
                  {Text["teamSection"][LANGUAGE]["Alex"]["name"]}
                </span>
                <span class="team-member-title">
                  {Text["teamSection"][LANGUAGE]["Alex"]["title"]}
                </span>
              </div>
            </div>
            <div class="team-member-container" id="team-divya">
              <img src={teamDivyaImage} alt="" />
              <div class="team-member-description-container">
                <span class="team-member-name">
                  {Text["teamSection"][LANGUAGE]["Divya"]["name"]}
                </span>
                <span class="team-member-title">
                  {Text["teamSection"][LANGUAGE]["Divya"]["title"]}
                </span>
              </div>
            </div>
            {/* <div class="team-member-container" id="team-christian">
                            <img src={teamChristianImage} alt=""/>
                            <div class="team-member-description-container">
                                <span class="team-member-name">{Text['teamSection'][LANGUAGE]['Christian']['name']}</span>
                                <span class="team-member-title">{Text['teamSection'][LANGUAGE]['Christian']['title']}</span>
                            </div>
                        </div> */}
            <div class="team-member-container" id="team-nikki">
              <img src={teamNikkiImage} alt="" />
              <div class="team-member-description-container">
                <span class="team-member-name">
                  {Text["teamSection"][LANGUAGE]["Nikki"]["name"]}
                </span>
                <span class="team-member-title">
                  {Text["teamSection"][LANGUAGE]["Nikki"]["title"]}
                </span>
              </div>
            </div>
          </div>
          {/* <div id="team-description-container">
                        <div class="team-member-description-container">
                            <h4>{Text['teamSection'][LANGUAGE]['Nikki']['name']}</h4>
                            <p>{Text['teamSection'][LANGUAGE]['Nikki']['title']}</p>
                        </div>
                        <div class="team-member-description-container">
                            <h4>{Text['teamSection'][LANGUAGE]['Alex']['name']}</h4>
                            <p>{Text['teamSection'][LANGUAGE]['Alex']['title']}</p>
                        </div>
                        <div class="team-member-description-container">
                            <h4>{Text['teamSection'][LANGUAGE]['Nick']['name']}</h4>
                            <p>{Text['teamSection'][LANGUAGE]['Nick']['title']}</p>
                        </div>
                        <div class="team-member-description-container">
                            <h4>{Text['teamSection'][LANGUAGE]['Divya']['name']}</h4>
                            <p>{Text['teamSection'][LANGUAGE]['Divya']['title']}</p>
                        </div>
                        <div class="team-member-description-container">
                            <h4>{Text['teamSection'][LANGUAGE]['Christian']['name']}</h4>
                            <p>{Text['teamSection'][LANGUAGE]['Christian']['title']}</p>
                        </div>
                    </div> */}
        </Fade>
      </section>
    );
  }
}

export class Investors extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <NavBar />
        <InvestorsHeroSection />
        {/* <TeamSection /> */}
        <Footer />
      </div>
    );
  }
}
