import React, { Component } from 'react';
import { Footer } from '../components/footer.js';
import './analyze_partners.css';
import { Jumbotron, Button, Nav, Navbar, NavItem, NavDropdown, MenuItem, Tab, Row, Col } from 'react-bootstrap';
import matchLabLogoName from '../assets/static-logos/11102018_matchlab_logo_name_PSD.png';
import matchLabNameOnly from '../assets/static-logos/11102018_matchlab_name_PSD.png';
import matchLabLogoOnly from '../assets/static-logos/11102018_matchlab_logo_PSD.png';
import { NavBar } from '../components/navbar.js';
import interfaceImage from '../assets/images/interface.png';
import detectionBefore from '../assets/images/detection_before.jpg';
import detectionAfter from '../assets/images/detection_after.jpg';
import standardizationBefore from '../assets/images/standardization_before.jpg';
import standardizationAfter from '../assets/images/standardization_after.jpg';
import ideasIcon from '../assets/icons/ideas-icon.png';
import nameCardIcon from '../assets/icons/name-card-icon.png';
import settingsIcon from '../assets/icons/settings-icon.png';
import flowMarketIcon from '../assets/icons/flow-market-icon.png';
import personIcon from '../assets/icons/person-icon.png';
import Text from '../assets/text/partnersText.json';

// import creamOnSkinImage from '../assets/images/cream_on_skin.jpg';
// import amberBottleImage from '../assets/images/amber_bottle_liquid.jpg';

const LANGUAGE = "English";

const styles = ({ // use 'styles={styles.matchLabNameOnly}' as attribute
    // ...: {
        // ...
    // },
    // ...: {
        // ...
    // }
  });

class DemoSection extends React.PureComponent{
    render(){
        return(
            <section className="demo-section" id="demo-section">
            <div className="demo-section-header section-header">
                <h2>Don't just take our word for it. See it for yourself.</h2> 
                {/* <h3>See it in action</h3> */}
            </div>
            <div className="demo-container">
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                <Row className="clearfix">
                    {/* <Col sm={4}> */}
                        <Nav bsStyle="pills" className="demo-tab-container" stacked>
                            <NavItem eventKey="first" className="demo-tab">Real-time Skin Analytics</NavItem>
                            <NavItem eventKey="second" className="demo-tab">Full-body Skin Segmentation</NavItem>
                        </Nav>
                    {/* </Col> */}
                    {/* <Col sm={8}> */}
                    <Col>
                        <Tab.Content animation>
                            <Tab.Pane eventKey="first" className="demo-content-container">
                                <p>High-throughput skin segmentation and feature annotation</p>
                                <div className="demo-before demo-container">
                                    <img src={ detectionBefore } alt="demo-image" />
                                </div>
                                <div className="demo-after demo-container">
                                    <img src={ detectionAfter } alt="demo-image" />
                                </div>
                            </Tab.Pane>
                            
                            <Tab.Pane eventKey="second" className="demo-content-container">
                                <p>Real-time adaptive skin detection for all skin tones</p>
                                <div className="demo-before demo-container">
                                    <img src={ standardizationBefore } alt="demo-image" />                                </div>
                                <div className="demo-after demo-container">
                                    <img src={ standardizationAfter } alt="demo-image" />
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
            </div>
        </section>
        )
    }
   
}

class HowItWorks extends React.PureComponent{
    render(){
        return(
            <section className="steps-section">
            <div className="section-header">
                <h2>{Text['stepsHeader'][LANGUAGE]}</h2>
            </div>
            <ol className="steps">
                <li className="step step-1">
                    <div className="step-description-container">
                        <h3>{Text['step1Header'][LANGUAGE]}</h3>
                        <div className="step-description">
                            <p>{Text['step1Description'][LANGUAGE]}</p>
                        </div>
                    </div>
                    <div className="step-image-container">
                        <img src={personIcon} alt="step-image" />
                    </div>
                </li>
                <li className="step step-2">
                    <div className="step-description-container">
                        <h3>{Text['step2Header'][LANGUAGE]}</h3>
                        <div className="step-description">
                            <p>{Text['step2Description'][LANGUAGE]}</p>
                        </div>
                    </div>
                    <div className="step-image-container">
                        <img src={flowMarketIcon} alt="step-image" />
                    </div>
                </li>
                <li className="step step-3">
                    <div className="step-description-container">
                        <h3>{Text['step3Header'][LANGUAGE]}</h3>
                        <div className="step-description">
                            <p>{Text['step3Description'][LANGUAGE]}</p>
                        </div>
                    </div>
                    <div className="step-image-container">
                        <img src={nameCardIcon} alt="step-image" />
                    </div>
                </li>
            </ol>
        </section>
        )
    }
}

class HeroSection extends React.PureComponent{
    render(){
        return(
            <section className="hero-section">
                <header className="hero-container">
                    {/* <h1 className="heroH1">MatchLab.ai</h1> */}
                    {/* <div className="heroImgContainer">
                        <img className="heroLogo" src={matchLabNameOnly} alt="matchlab" />
                    </div> */}
                    <div className="hero-section-header">
                        <h1 className="hero-header">
                            {Text['heroHeader'][LANGUAGE]}
                            {/* {Text['heroText'][LANGUAGE].split(" ").slice(0,4).join(" ") + " "} 
                            <i>
                                {Text['heroText'][LANGUAGE].split(" ").slice(4,7).join(" ") + " "}
                            </i>
                            {Text['heroText'][LANGUAGE].split(" ").slice(7).join(" ")} */}
                        </h1>
                        <p className="hero-sub-header">
                            {Text['heroSubHeader'][LANGUAGE]}
                        </p>
                        <a href="#demo-section"><button className="heroCTA">{Text['heroCTA'][LANGUAGE]}</button></a>
                    </div>

                </header>
            </section>
        )
    }
}

export class AnalyzePartners extends Component {
  constructor(props) {
    super(props);
    this.state = {  }
  }
  render() {
    return ( 
        <div>
            <NavBar />
            <HeroSection/>
            <HowItWorks/>
            <DemoSection/>
            <Footer />
        </div>
     );
  }
}

{/* // export default AnalyzePartner; */}
