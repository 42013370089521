import React, { Component } from "react";
import "./pulsing_camera_button.css";
// import { Glyphicon } from "react-bootstrap";
import { IoCameraOutline } from "react-icons/io5";

export default class PulsingCameraButton extends React.Component {
	constructor(props) {
		super(props);
		this.state = {};

		this.handleCameraFocus = this.handleCameraFocus.bind(this);
	}

	handleCameraFocus() {
		// debugger;
		document.getElementById("background-focused-lg").style.opacity = "0";
		document.getElementById("hand-focused-lg").style.opacity = "1";

		document.getElementById("background-focused-md").style.opacity = "0";
		document.getElementById("hand-focused-md").style.zIndex = "1";

		document.getElementById("background-focused-sm").style.opacity = "0";
		document.getElementById("hand-focused-sm").style.opacity = "1";

		document.getElementById("pulse-btn").classList.add("paused");
	}

	render() {
		return (
			<div
				className="pulse-btn-container"
				id="pulse-btn"
				onClick={(e) => this.handleCameraFocus(e)}
				// onHover
				style={{ justifyContent: "center", alignItems: "center" }}
			>
				<IoCameraOutline
					// id="glyph-camera"
					style={{
						color: "#2b7bf2",
						fontSize: 30,
						zIndex: -1,
						flex: 1,
						position: "absolute",
						top: 10,
						left: 10,
					}}
				/>
				{/* <Glyphicon /> */}
			</div>
		);
	}
}
