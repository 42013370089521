import React, { Component } from "react";
import "./navbar.css";
import { Jumbotron, Button, Nav, Navbar, NavItem, NavDropdown, Row, Col } from "react-bootstrap";
import matchLabLogoName from "../assets/static-logos/11102018_matchlab_logo_name_PSD.png";
import matchLabNameOnly from "../assets/static-logos/11102018_matchlab_name_PSD.png";
import matchLabLogoOnly from "../assets/static-logos/11102018_matchlab_logo_PSD.png";
import { NavLink } from "react-router-dom";

// import AnalyzePartners from '../screens/analyzePartners.js';

export class NavBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div className="nav-bar-wrapper">
        <Navbar className="nav-bar" collapseOnSelect fixedTop fluid>
          <Navbar.Header>
            <Navbar.Brand>
              {/* <a href="#brand"><img className="logo" src={matchLabLogoOnly} alt="matchlab-logo" /></a> */}
              <NavLink className="logo-link nav-link" style={{ textDecoration: "none" }} to="/">
                <img className="nav-logo" src={matchLabLogoName} alt="matchlab-logo" />
              </NavLink>
              {/* <a className="logo-link" href="#brand">
                            <img className="nav-logo" src={matchLabLogoName} alt="matchlab-logo" />
                        </a> */}
            </Navbar.Brand>
            <Navbar.Toggle />
          </Navbar.Header>
          <Navbar.Collapse>
            <Nav>
              <NavItem eventKey={1} href="/#about">
                <a className="nav-link">About</a>
              </NavItem>
              {/* <NavItem eventKey={2} href="/#products">
                        <a className="nav-link">Products</a>
                    </NavItem> */}

              <NavDropdown eventKey={2} title="Products" id="nav-product-dropdown">
                <NavDropdown.Item eventKey={2.1} href="/matchsmart">
                  MatchSmart
                </NavDropdown.Item>
                <NavDropdown.Item eventKey={2.2} href="/matchanalytics">
                  MatchAnalytics
                </NavDropdown.Item>
                {/* <NavDropdown.Item eventKey={2.3}>Something else here</NavDropdown.Item> */}
                {/* <NavDropdown.Item divider />
                        <NavDropdown.Item eventKey={2.3}>Separated link</NavDropdown.Item> */}
              </NavDropdown>
              <NavItem eventKey={3} href="/#partners">
                <a className="nav-link">Partners</a>
              </NavItem>
              <NavItem eventKey={4} href="#">
                {/* <a className="nav-link" href="#partners">Investors</a> */}
                <NavLink className="nav-link" style={{ textDecoration: "none" }} to="/investors">
                  Investors
                </NavLink>
              </NavItem>
              {/* <NavItem eventKey={1} href="#">
                            <NavLink className="nav-link" style={{ textDecoration: 'none' }} to="/partners">Partners</NavLink> 
                        </NavItem>
                        <NavItem eventKey={2} href="#">
                            <NavLink className="nav-link" style={{ textDecoration: 'none' }} to="/dermatologists">Dermatologists</NavLink>
                        </NavItem> */}
            </Nav>
            <Nav pullRight>
              {/* <NavItem eventKey={1} href="#">
                            <NavLink className="nav-link" style={{ textDecoration: 'none' }} to="/signin">Sign In</NavLink>
                        </NavItem>
                        <NavItem eventKey={2} href="#">
                            <NavLink className="nav-link" style={{ textDecoration: 'none' }} to="/signup">Sign Up</NavLink>
                        </NavItem> */}
              <NavItem eventKey={3} href="mailto:info@matchlab.ai">
                <a className="nav-link" href="mailto:info@matchlab.ai">
                  Contact
                </a>
              </NavItem>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

{
  /* // export default NavBar; */
}
