import React, { Component } from "react";
import { Footer } from "../components/footer.js";
import "./investors.css";
import {
  Jumbotron,
  Button,
  Form,
  FormGroup,
  ControlLabel,
  FormControl,
  Nav,
  Navbar,
  NavItem,
  NavDropdown,
  MenuItem,
  Tab,
  Row,
  Col,
} from "react-bootstrap";
import matchLabLogoName from "../assets/static-logos/11102018_matchlab_logo_name_PSD.png";
import matchLabNameOnly from "../assets/static-logos/11102018_matchlab_name_PSD.png";
import matchLabLogoOnly from "../assets/static-logos/11102018_matchlab_logo_PSD.png";
import { NavBar } from "../components/navbar.js";
import Fade from "react-reveal/Fade";
import Text from "../assets/text/homepageText.json";

const LANGUAGE = "English";

class HeroSection extends React.PureComponent {
  render() {
    return (
      <section className="hp-hero-section">
        <header className="hp-hero-container">
          {/* <h1 className="heroH1">MatchLab.ai</h1> */}
          {/* <div className="heroImgContainer">
                        <img className="heroLogo" src={matchLabNameOnly} alt="matchlab" />
                    </div> */}
          <Fade bottom delay={1000} distance={"50px"}>
            <div className="hp-hero-section-header">
              <h2 className="hero-header">MatchSmart for Surgeons - Coming Soon</h2>
              <form
                id="hero-signup"
                className="signup-form validate"
                action="https://nickrance.us16.list-manage.com/subscribe/post?u=4da03f423ea15a7261de121d2&amp;id=44e5aa8dfb"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                target="_blank"
                novalidate=""
              >
                <input type="email" id="mce-EMAIL" name="EMAIL" placeholder="Email address" />
                <button type="submit">Get our updates</button>
              </form>
            </div>
          </Fade>
        </header>
      </section>
    );
  }
}

export class MatchSmart extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <NavBar />
        <HeroSection />
        <Footer />
      </div>
    );
  }
}
